import { defineRule, configure } from 'vee-validate'
import { email, min, required, confirmed, size } from '@vee-validate/rules'

configure({
    validateOnModelUpdate: false,

})

defineRule('required', required)
defineRule('email', email)
defineRule('min', min)
defineRule('confirmed', confirmed)
defineRule('size', size)
