import {defineStore} from "pinia";
import {ref} from "vue";

const useLayout = defineStore('layout', () => {
    const title = ref("")
    const setTitle = (t) => title.value = t
    return {title, setTitle}
})


export default useLayout