export const DEFAULT_CONFIG = {
    'auth-server-url': 'https://id.platon.uz/',
    realm: 'developers',
    resource: 'database-control-ui',
    onLoad: 'login-required',
}


const getDefaultConfig = () => {
    return {
        url: process.env.VUE_APP_AUTH_SERVER_URL || DEFAULT_CONFIG["auth-server-url"],
        realm: process.env.VUE_APP_REALM || DEFAULT_CONFIG.realm,
        clientId: process.env.VUE_APP_RESOURCE || DEFAULT_CONFIG.resource,
        onLoad: DEFAULT_CONFIG.onLoad
    }
}


export const getConfig = async () => {
    return getDefaultConfig()
}