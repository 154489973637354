import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Keycloak from "keycloak-js";
import Notifications from "@kyvg/vue3-notification";
import "./styles/main.scss";
import "./modules/vee-validate";
import $http from "./utils/http";

import { getConfig } from "@/constants";
import { createPinia } from "pinia";
import { parseJwt } from "@/utils/pureFunctions";
import useUser from "@/store/user";

let kc;

const initKeycloak = async () => {
  const options = await getConfig();
  const keycloak = new Keycloak(options);
  kc = keycloak;

  await keycloak
    .init({
      onLoad: options.onLoad,
    })
    .then((auth) => {
      if (!auth) {
        window.location.reload();
      } else {
        return Promise.resolve(keycloak);
      }

      setInterval(() => {
        keycloak
          .updateToken(10)
          .then((refreshed) => {
            console.log(refreshed);
            if (refreshed) {
              console.log("Token refreshed" + refreshed);
            } else {
              console.log(
                "Token not refreshed, valid for " +
                  Math.round(
                    keycloak.tokenParsed.exp +
                      keycloak.timeSkew -
                      new Date().getTime() / 1000
                  ) +
                  " seconds"
              );
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }, 6000);
    })
    .catch((e) => {
      console.error(e);
      return Promise.reject(e);
    });
};

const initApp = (keycloak) => {
  const app = createApp(App);
  app.use(Notifications);
  app.config.globalProperties.$keycloak = keycloak;
  app.use(createPinia());
  const { setUser } = useUser();
  setUser(parseJwt(keycloak.token));
  app.use($http);
  app.use(router).mount("#app");
};

initKeycloak().then(() => {
  initApp(kc);
});
