export const PERMISSIONS = {
  profile: "profile",
  profile_change_password: "profile_change_password",

  user: "user",
  user_add: "user_add",
  user_delete_project: "user_delete_project",
  user_delete_database_admin: "user_delete_database_admin",
  user_add_database_admin: "user_add_database_admin",
  user_status_change: "user_status_change",
  user_add_project: "user_add_project",
  user_update: "user_update",
  user_delete: "user_delete",

  agent: "agent",

  role: "role",

  database: "database",
  database_add_user: "database_add_user",
  database_edit_user: "database_edit_user",
  database_delete_user: "database_delete_user",

  platon_project: "platon_project",
  platon_project_update_front: "platon_project_update_front",
  platon_project_update_backend: "platon_project_update_backend",
  platon_project_delete: "platon_project_delete",

  platon_release: "platon_release",
  platon_release_backend: "platon_release_backend",
  platon_release_frontend: "platon_release_frontend",
  platon_release_create_backend: "platon_release_create_backend",
  platon_release_update_front: "platon_release_update_front",
  platon_release_update_backend: "platon_release_update_backend",
};
