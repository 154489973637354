import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '../layouts/default'
import useUser from "@/store/user";
import {PERMISSIONS} from "@/constants";

const routes = [
  {
    path: '/',
    redirect: {name: 'main-profile'}
  },
  {
    path: '/main',
    component: DefaultLayout,
    children: [
      {
        path: 'profile',
        name: 'main-profile',
        component: () => import('../pages/main/index'),
        meta: {
          withInnerLayoutContainer: false,
          access: PERMISSIONS.profile
        },
      },
      {
        path: 'user',
        name: 'main-user',
        component: () => import('../pages/main/user'),
        meta: {
          access: PERMISSIONS.user
        }
      },

      {
        path: 'agent',
        name: 'main-agent',
        component: () => import('../pages/main/agent'),
        meta: {
          access: PERMISSIONS.agent
        }
      },

      {
        path: 'role',
        name: 'main-role',
        component: ()=> import('../pages/main/role'),
        meta: {
          access: PERMISSIONS.role
        }
      },
      {
        path: 'database',
        name: 'main-database',
        component: ()=> import('../pages/main/database'),
        meta: {
          access: PERMISSIONS.database
        }
      },
      {
        path: 'platon-project',
        name: 'main-platon-project',
        component: ()=> import('../pages/main/platonProject'),
        meta: {
          access: PERMISSIONS.platon_project
        }
      },
      {
        path: 'platon-releases',
        name: 'main-platon-releases',
        component: ()=> import('../pages/main/platonReleases'),
        meta: {
          access: PERMISSIONS.platon_release
        }
      },
      {
        path: '/not-allowed',
        name: 'not-allowed',
        component: ()=> import('../pages/main/notAllowed'),
      },
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to, from, next) => {
  const {hasAccess}= useUser()
  if(to?.meta?.access && !hasAccess(to.meta.access)) {
    return next({name: 'not-allowed'})
  }
  next()
});

export default router
