import axios from 'axios'

const TOKEN_KEY = 'Authorization'

const TokenService = {
  // GET
  getToken() {
    return localStorage.getItem(TOKEN_KEY)
  },
  saveToken(token) {
    localStorage.setItem(TOKEN_KEY, token)
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY)
    localStorage.clear()
  },
}

export default TokenService
