import axios from "axios";

import TokenService from "./TokenService";

const isDevelopment = process.env.NODE_ENV === "development";

export const http = axios.create({
  //   baseURL:  isDevelopment ?  'https://dbc.platon.uz/' : '/'
  baseURL: isDevelopment ? "http://localhost:8080/" : "/",
});

export default {
  install(app) {
    const token = app.config.globalProperties.$keycloak.token;
    http.interceptors.request.use(
      (config) => {
        if (token) config.headers.Authorization = `Bearer ${token}`;
        config.headers.Accept = "application/json";
        config.headers["Access-Control-Allow-Origin"] = "*";
        // config.headers['Content-Type'] = 'application/json'
        return config;
      },
      (error) => Promise.reject(error)
    );
    http.interceptors.response.use(
      (res) => res,
      (error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          TokenService.removeToken();
          app.config.globalProperties.$keycloak.logout();
        }
        return Promise.reject(error);
      }
    );

    app.config.globalProperties.$http = http;
  },
};
