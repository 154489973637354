import {defineStore} from "pinia";
import {computed, ref} from "vue";

const useUser = defineStore('user', () => {

    const user = ref(null)
    const userPermissions = computed(() => {
        const permissions = {}
        if (user.value?.resource_access['database-control-ui']?.roles && Array.isArray(user.value?.resource_access['database-control-ui']?.roles)) {
            user.value.resource_access['database-control-ui'].roles.forEach(item => {
                permissions[item] = true
            })
        }
        return permissions
    })

    /**
     * @param key {string}
     */
    const hasAccess = (key) => {
        return !!userPermissions.value[key]
    }

    /**
     * @param userdata {UserData}
     */
    const setUser = (userdata) => {
        user.value = userdata
    }


    return {
        user,
        userPermissions,
        hasAccess,
        setUser
    }
})


export default useUser
