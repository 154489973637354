<script setup>
import useLayout from "@/store/useLayout";
import UserProfile from "@/components/user/UserProfile";

const layoutStore = useLayout()


</script>

<template>
  <div class="header">
    <h1 class="header__title">{{ layoutStore.title }}</h1>
    <UserProfile/>
  </div>
</template>


<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  height: 70px;
  margin: -16px -16px 0 -16px;
  background: #fff;
  padding: 16px;
  font-size: 20px;
  font-weight: 600;
  box-shadow: 0 5px 2px rgba(33, 83, 115, 0.01), 0 3px 2px rgba(33, 83, 115, 0.05), 0 1px 1px rgba(33, 83, 115, 0.09), 0 1px rgba(33, 83, 115, 0.1);

  &__title {
    color: #2A3B69;
    padding-left: 30px;
  }
}


</style>
