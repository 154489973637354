import {defineStore} from "pinia";

export const asideStatus = defineStore('taskStore', {
    state: () => ({
        isHide: false
    }),
    actions: {
        changeStateAside(status) {
            this.isHide = status
        }
    }
})
