<script setup>
import '../styles/homeLayout.scss'
import TheSidebar from "@/components/TheSidebar";
import TheHeader from "@/components/TheHeader";
import {asideStatus} from '@/store/asideStatus';

const isHideStatus = asideStatus()

</script>

<template>
  <main class="main">
    <TheSidebar/>
    <div :class="{hide: isHideStatus.isHide===true}" class="inner">
      <TheHeader/>
        <RouterView />
    </div>
  </main>
</template>

