<script setup>
import {onClickOutside} from "vue-click-outside-of";
import {ref, onMounted, getCurrentInstance} from "vue";

const {appContext} = getCurrentInstance()
let isOpened = ref(false)
const {$keycloak} = appContext.config.globalProperties
const userName = ref('')

const UserProfileRef = ref(null);
const option = {
  type: "downUp",
  before: () => {
    return true;
  },
};

onClickOutside(() => {
  isOpened.value = false
}, UserProfileRef, option);


const logout = () => {
  isOpened.value = false
  $keycloak.logout()
}
const loading = ref(false)
const getUserInfo = async () => {
  loading.value = true
  const user = await $keycloak.loadUserProfile()
  userName.value = user.username
  loading.value = false
}

onMounted(() => {
  loading.value = true
  setTimeout(() => {
    getUserInfo()
  }, 300)
})


</script>

<template>
  <div ref="UserProfileRef" :class="['user-profile', {'user-profile-active': isOpened}]">
    <div class="user-profile__header" @click="isOpened = !isOpened">
      <p>{{ loading ? '...' : userName }}</p>
      <span class="user-profile__icon">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.83325 7.91663L9.99992 12.0833L14.1666 7.91663H5.83325Z" fill="#2A3B69"/>
      </svg>
      </span>
    </div>
    <ul class="user-profile__options">
      <li
          class="user-profile__option">
        <router-link :to="{ name: 'main-profile'}">
          <div class="user-profile__options__item">
            <span class="user-profile__options__item__img"><img src="@/assets/icons/person.svg" alt=""></span>
            <span>Profile</span>
          </div>
        </router-link>
      </li>
      <li
          @click="logout"
          class="user-profile__option">
        <div class="user-profile__options__item">
          <span class="user-profile__options__item__img"><img src="@/assets/icons/logout.svg" alt=""></span>
          <span>Log out</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.user-profile {
  $self: &;
  font-size: 14px;
  color: #2A3B69;
  position: relative;
  height: 70px;
  margin-top: -16px;
  display: flex;
  align-items: center;

  &-active {
    #{$self}__icon {
      transform: rotate(180deg);
    }

    #{$self}__options {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }

  &__icon {
    display: inline-block;
    @apply transition;
  }

  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;
    min-width: 80px;
    justify-content: space-between;
  }

  &__options {
    width: 125px;
    right: 0;
    left: unset;
    position: absolute;
    box-shadow: 0 5px 2px rgba(33, 83, 115, 0.01), 0 3px 2px rgba(33, 83, 115, 0.05), 0 1px 1px rgba(33, 83, 115, 0.09), 0 0 1px rgba(33, 83, 115, 0.1), 0 0 0 rgba(33, 83, 115, 0.1);
    border-radius: 4px;
    background: #fff;
    border: 1px solid #E0E9F1;
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
    top: 70%;
    z-index: 10;
    @apply transition;
    &__item{
      display: flex;
      align-items: center;
      &__img{
        @apply mr-3;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
      }
    }
  }

  &__option {
    padding: 10px 12px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: #e7e5e5;
    }
  }
}
</style>
